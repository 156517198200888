<template>
  <v-card height="95vh" flat tile class="mb-n15 primarygrad">
    <v-row justify="center" align="center">
      <v-card width="800" class="ma-5 pa-5 mt-15">
        <v-img
          class="circle rounded mx-auto"
          src="../assets/logo.png"
          width="45"
          height="45"
        ></v-img>
        <!-- <v-card-title class="text-md-h6 justify-center"
          >Verify Your Email</v-card-title
        > -->
        <v-img class="mx-auto" width="200" src="../assets/verify.jpg"></v-img>
        <v-card-text class="text-center">
          <h5 class="text-md-h5 font-weight-bold">
            Thanks for Signing Up
          </h5>
          <p class="text-md-body-1 font-weight-regular">
            Please check your inbox for activation link
          </p>
        </v-card-text>
        <v-card-actions>
          <p class="text-md-caption my-3">Did not receive email?</p>
          <v-btn class="ml-5 primary" @click="verifyEmail">Resend</v-btn>

          <p class="text-md-caption ml-auto my-3">Wrong Email?</p>
          <v-btn class="ml-5 error" @click="logout"
            ><v-icon left>mdi-logout-variant</v-icon> Logout</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    this.getManager();
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
  },
  data() {
    return {
      loaderDialog: false,
      managerAccount: {},
      isEmailVerified: false,
      email: "",
    };
  },
  methods: {
    async getManager() {
      var response = await this.$http.get(
        `${this.$store.state.ENDPOINT}/accounts`
      );

      this.managerAccount = response.body.filter((account) => {
        return account.role === "Manager";
      });
      this.managerAccount = this.managerAccount[0];
      this.email = this.managerAccount.email;
      this.isEmailVerified = this.managerAccount.isEmailVerified ? true : false;
    },
    verifyEmail() {
      console.log("send email to maanger's account", this.managerAccount);

      if (!this.managerAccount.email) {
        return this.$swal({ type: "warning", text: "Please add email" });
      }

      this.$http
        .post(`${this.ENDPOINT}/accounts/send-verification-email`, {
          email: this.managerAccount.email,
        })
        .then((response) => {
          this.$swal({
            type: "success",
            text: `Email sent to ${this.managerAccount.email}.Please check your mail to verify your email.`,
          });
        })
        .catch((e) => {
          this.$swal({ type: "error", text: e.body.error.message });
        });
    },
    logout() {
      //this.$store.dispatch("logout");
      const self = this;

      this.$http
        .post(this.$store.state.ENDPOINT + "/accounts/logout")
        .then(() => {
          self.$store.commit("setAllUsers", null);
          self.$store.commit("setUser", null);
          window.localStorage.clear();
          window.location.reload();

          fb.logout();
        })
        .catch(() => {
          self.$store.commit("setAllUsers", null);
          self.$store.commit("setUser", null);
          window.localStorage.clear();
          window.location.reload();
        });
    },
  },
};
</script>

<style scoped>
.circle {
  border-radius: 50%;
}
</style>
